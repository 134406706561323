import { BrowserRouter } from 'react-router-dom';
import { GrowlScene } from '@crystallize/react-growl';

import Routes from './routes';
import GlobalStyles from './styles/global';

function App() {
  return (
    <BrowserRouter>
      <Routes />
      <GrowlScene />
      <GlobalStyles />
    </BrowserRouter>
  );
}

export default App;
