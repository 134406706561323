import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  :root {
    --primary-color: #00aeef;
  }
  
  *{
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box;
  }

  body, p {
    font: 400 15px 'Roboto Slab', sans-serif;
  }

  strong {
    font-family: 'Roboto Slab';
  }

  h1 {
    font: 600 24px 'Roboto Slab';
  }
`;
