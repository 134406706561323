import { Switch, Route, Redirect } from 'react-router-dom';

import Tracking from '../pages/Tracking';

function Routes() {
  return (
    <Switch>
      <Route path="/" exact render={() => <Redirect to="/tracking" />} />
      <Route path="/tracking" component={Tracking} />
    </Switch>
  );
}

export default Routes;
